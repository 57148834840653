<template>
  <div v-if="ready">
    <div class="card-deck">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label"><a :href="'/#/devis/detail/'+devis.id">{{$store.back.getText('devis')}} Ref #{{devis.id}}</a> {{devis.nom}} {{devis.prenom}}</h3>
          </div>
          <div class="card-toolbar div-center">
            <b-button variant="warning" size="sm" class="btn-icon mr-1" :href="'mailto:'+devis.email"><i class="icon-md fas fa-envelope"></i></b-button>
            <b-button variant="primary" size="sm" class="btn-icon mr-1" :href="'tel:'+devis.telephone"><i class="icon-md fas fa-phone"></i></b-button>
            <b-button variant="primary" size="sm" class="btn-icon mr-1" :href="'tel:'+devis.mobile"><i class="icon-md fas fa-mobile"></i></b-button>
            <b-button variant="danger" size="sm" class="btn-icon mr-1" @click="devisToPdf()">
              <i v-if="pdfLoader === false" class="icon-md fas fa-file-pdf"></i>
              <i v-else class="fas fa-circle-notch fa-spin"></i>
            </b-button>
          </div>
        </div>
        <div class="card-body">
          <iframe style="width:100%; height:800px" :srcdoc="html"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      ready:false,
      html:"",
      pdfLoader: false,
      devis:this.$store.back.getSchema('Devis')
    };
  },
  components: {},
  watch: {},
  methods: {
    devisToPdf(){
      this.pdfLoader = true
      var url = this.$store.back.urlAPI+`/devis/pdf/${this.devis.id}`;
      fetch(url, {
        method: 'GET',
        headers: new Headers({
          "Authorization": this.$store.back.getToken()
        })
      })
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `${this.devis.nom}-${this.devis.prenom}-${this.devis.id}.pdf`
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.pdfLoader = false;
      });
    }
  },
  computed: {},
  beforeMount(){
    this.$store.back.ajax(`/devis/${this.$route.params.id}`, null, (res) => {
      if(res.status){
        this.devis = res.data;
        this.$store.back.ajax(`/devis/edit/${this.devis.id}`, null, (res) => {
          if(res.status){
            this.html = res.data;
            this.ready = true;
          }
        });
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
  }
};
</script>
